import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Box, Text, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';

import { NO_DATA_PLACEHOLDER } from '../common/AnalyticsTable';

const i18n = defineMessages({
	engagedLozengeLabel: {
		id: 'confluence-analytics.engagement-cell.engaged',
		defaultMessage: 'Engaged',
		description:
			'Label for the lozenge displayed in the engagement cell of the page views table when a user has met engagement criteria with a content piece.',
	},
	briefLozengeLabel: {
		id: 'confluence-analytics.engagement-cell.brief',
		defaultMessage: 'Brief',
		description:
			'Label for the lozenge displayed in the engagement cell of the page views table when a user has not met engagement criteria with a content piece.',
	},
});

const EngagementCellStyles = xcss({
	paddingLeft: 'space.500',
});

type EngagementCellProps = {
	lastViewDate?: string;
	isEngaged?: boolean | null;
};

export const EngagementCell = ({ isEngaged, lastViewDate }: EngagementCellProps) => {
	if (!lastViewDate) {
		return (
			<Box xcss={EngagementCellStyles} testId="engagement-cell">
				<Text color="color.text.disabled">{NO_DATA_PLACEHOLDER}</Text>
			</Box>
		);
	}

	return (
		<Box xcss={EngagementCellStyles} testId="engagement-cell">
			<Lozenge appearance={isEngaged ? 'inprogress' : 'moved'}>
				<FormattedMessage {...(isEngaged ? i18n.engagedLozengeLabel : i18n.briefLozengeLabel)} />
			</Lozenge>
		</Box>
	);
};
