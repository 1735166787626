import React from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { ContentAnalyticsLink } from '../ContentAnalyticsLink';
import { TestId } from '../dialogConstants';
import { AnalyticsDialogFooterSkeleton } from '../LoadingSkeleton';
import type { PageAnalyticsTab } from '../../PageAnalytics/pageAnalyticsConstants';

import * as S from './styled';

export type AnalyticsDialogFooterProps = {
	isLoading: boolean;
	contentId: string;
	contentTab?: PageAnalyticsTab;
	onClickAnalyticsLink?: () => void;
};

const i18n = defineMessages({
	viewMoreInsightsButton: {
		id: 'confluence-analytics.view-more-insights.button',
		defaultMessage: 'View more insights',
		description: 'A label on a button to view more analytics.',
	},
});

export const AnalyticsDialogFooter: FC<AnalyticsDialogFooterProps> = ({
	isLoading,
	contentId,
	contentTab,
	onClickAnalyticsLink,
}) => {
	return (
		<>
			{isLoading ? (
				<AnalyticsDialogFooterSkeleton data-testid={TestId.LINK_SKELETON} />
			) : (
				<S.FooterContainer>
					<ContentAnalyticsLink
						contentId={contentId}
						contentTab={contentTab}
						testId={TestId.LINK}
						onClick={onClickAnalyticsLink}
					>
						<FormattedMessage {...i18n.viewMoreInsightsButton} />
					</ContentAnalyticsLink>
				</S.FooterContainer>
			)}
		</>
	);
};
