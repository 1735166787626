import type { ApolloError } from 'apollo-client';

import { isInternalUser, isPermissionError, isSSRError } from '../components/analyticsUtils';
import type { ContentAnalyticsViewersUnifiedQuery as GraphqlQueryData } from '../queries/__types__/ContentAnalyticsViewersUnifiedQuery';

export const useHideByline = (
	loading: boolean,
	data?: GraphqlQueryData,
	error?: ApolloError,
): boolean => {
	return loading || isSSRError(data, error) || isPermissionError(error) || !isInternalUser(data);
};
