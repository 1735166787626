import { styled } from '@compiled/react';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { ButtonProps } from '@atlaskit/button/standard-button';
import Button from '@atlaskit/button/standard-button';
import ChartTrendIcon from '@atlaskit/icon/core/chart-trend';
import { token } from '@atlaskit/tokens';
import { xcss, Box } from '@atlaskit/primitives';

import { ChangeEditionSectionMessagePageInsights } from '@confluence/change-edition/entry-points/pageInsights';
import {
	ANALYTICS_DIALOG_UPSELL_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { expValEquals } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';

import { SSREvent } from '../../hooks';
import { BadgeStyleAnalyticsByline } from '../../base-components/BadgeStyleAnalyticsByline';

import type { UseAnalyticsByLineHook } from './UseAnalyticsByLineHook';

const standardWrapperStyles = xcss({
	width: '350px',
});

const popupContentExperience = ANALYTICS_DIALOG_UPSELL_EXPERIENCE;

export const useAnalyticsByLineUpsell: UseAnalyticsByLineHook = () => {
	const isLivePage = useIsCurrentPageLive();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const onPopupTriggerSSREvent = useCallback(
		(event: SSREvent) => {
			if (event === SSREvent.CLICK) {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'pageInsightGate',
						source: 'pageInsights',
						attributes: {
							isSiteAdmin,
							isLivePage,
						},
					},
				}).fire();
			}
		},
		[createAnalyticsEvent, isSiteAdmin, isLivePage],
	);

	return {
		hideAnalyticsByLine: false,
		loading: false,

		//
		// Popup content
		//

		popupContent: () => (
			<Box as="section" xcss={standardWrapperStyles}>
				<ChangeEditionSectionMessagePageInsights>
					<ExperienceSuccess name={popupContentExperience} />
				</ChangeEditionSectionMessagePageInsights>
			</Box>
		),

		popupContentExperience,

		//
		// Popup trigger
		//

		popupTrigger: ({ onClick }) => (
			<PopupTrigger
				onClick={onClick}
				abbreviatePageViews={
					isLivePage || expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test')
				}
				useBadgeStyle={
					fg('cc_page_experiences_byline_badge_style') &&
					(isLivePage || expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test'))
				}
			/>
		),

		onPopupTriggerSSREvent,
	};
};

const i18n = defineMessages({
	byLineUpsellText: {
		id: 'confluence-analytics.byLineUpSell.buttonText',
		defaultMessage: 'See how many people viewed this',
		description: 'Analytics button text which would show how many people viewed a page',
	},
	byLineAbbreviatedUpsellText: {
		id: 'confluence-analytics.abbreviatedByLineUpSell.buttonText',
		defaultMessage: 'See views',
		description: 'Abbreviated analytics button text which would show how many people viewed a page',
	},
});

// Below Icon and Button styles are copied from @atlassian/analytics-byline to keep the styles consistent

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AnalyticsIconWrapper = styled.span({
	marginRight: token('space.050'),
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ByLineButtonWrapperWithDot = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		content: '"•"',
		display: 'inline-block',
		color: token('color.text.subtle'),
		paddingRight: token('space.100'),
		/* for IE <= 11 */
		textDecoration: 'none',
	},
});

type PopupTriggerProps = Pick<ButtonProps, 'onClick'> & {
	abbreviatePageViews?: boolean;
	useBadgeStyle?: boolean;
};

const PopupTrigger = (props: PopupTriggerProps) => {
	const { abbreviatePageViews, onClick, useBadgeStyle } = props;
	const BylineButtonWrapper = abbreviatePageViews ? Box : ByLineButtonWrapperWithDot;

	const analyticsBylineUpsellMessage = (
		<FormattedMessage
			{...(abbreviatePageViews ? i18n.byLineAbbreviatedUpsellText : i18n.byLineUpsellText)}
		/>
	);

	return (
		<BylineButtonWrapper>
			{!useBadgeStyle ? (
				<Button
					onClick={onClick}
					appearance="subtle-link"
					// leaving as-is for now, as this is a temporary solution -- ENGHEALTH-24908/CCECO-4418
					// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={{ fontWeight: 'unset' }}
					spacing="none"
				>
					<AnalyticsIconWrapper aria-hidden="true">
						<ChartTrendIcon label="" />
					</AnalyticsIconWrapper>
					{analyticsBylineUpsellMessage}
				</Button>
			) : (
				<BadgeStyleAnalyticsByline
					onClick={onClick}
					message={analyticsBylineUpsellMessage}
					iconLabel=""
				/>
			)}
		</BylineButtonWrapper>
	);
};
