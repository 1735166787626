import { styled } from '@compiled/react';
import type { ComponentProps } from 'react';

import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

type HoverableButtonProps = ComponentProps<typeof Button> & {
	hoverTextDecoration: 'none' | 'underline';
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentAnalyticsLinkButton = styled<HoverableButtonProps>(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		alignItems: 'center',
		font: token('font.body'),
		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			textDecorationLine: `${(props: HoverableButtonProps) =>
				props.hoverTextDecoration} !important`,
		},
	},
});
