import React from 'react';

import { Anchor, Inline, Box, xcss } from '@atlaskit/primitives';

import { fg } from '@confluence/feature-gating';
import { useSpaceKey } from '@confluence/space-utils';
import { PAGE_ANALYTICS } from '@confluence/named-routes';

import { PageAnalyticsTab } from '../../PageAnalytics/pageAnalyticsConstants';

import * as S from './ContentAnalyticsLinkStyled';

const containerStyles = xcss({
	width: '100%',
});

const AnchorStyles = xcss({
	width: '100%',
	textAlign: 'center',
	textDecoration: 'none',
	':hover': {
		textDecoration: 'none',
	},
});

const ViewMoreButtonStyles = xcss({
	width: '100%',
	height: '32px',
	borderRadius: 'border.radius.050',
	textDecoration: 'none',
	backgroundColor: 'color.background.neutral',
	color: 'color.text',
	fontWeight: 'font.weight.medium',
	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.pressed',
	},
});

export type ContentAnalyticsLinkProps = React.PropsWithChildren<{
	contentId?: string;
	contentTab?: PageAnalyticsTab;
	testId?: string;
	onClick?: () => void;
}>;

export const ContentAnalyticsLink = ({
	contentId,
	contentTab,
	onClick,
	testId,
	children,
}: ContentAnalyticsLinkProps) => {
	const spaceKey = useSpaceKey() || ``;

	// spaceKey and contentId are required for PAGE_ANALYTICS.toUrl
	if (!spaceKey || !contentId) {
		return null;
	}

	const pageAnalyticsURL = PAGE_ANALYTICS.toUrl({
		spaceKey,
		contentId,
		tab: contentTab ? PageAnalyticsTab[contentTab].toLowerCase() : undefined,
	});

	if (fg('confluence_frontend_analytics_ui_links_view')) {
		return (
			<Box paddingBlockStart="space.200" xcss={containerStyles}>
				<Anchor href={pageAnalyticsURL} onClick={onClick} testId={testId} xcss={AnchorStyles}>
					<Inline xcss={ViewMoreButtonStyles} alignInline="center" alignBlock="center">
						{children}
					</Inline>
				</Anchor>
			</Box>
		);
	}

	return (
		<S.ContentAnalyticsLinkButton
			href={pageAnalyticsURL}
			onClick={onClick}
			testId={testId}
			shouldFitContainer={false}
			appearance="link"
			hoverTextDecoration="underline"
		>
			{children}
		</S.ContentAnalyticsLinkButton>
	);
};
