import React from 'react';
import type { HTMLProps } from 'react';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';

const analyticsIconWrapperStyles = css({
	marginRight: token('space.050'),
	verticalAlign: 'middle',
});

export const AnalyticsIconWrapper = (props: HTMLProps<HTMLSpanElement>) => (
	<span {...props} css={[analyticsIconWrapperStyles]} />
);

const analyticsBylineWrapperStyles = css({
	display: 'inline-block',
	color: token('color.text.subtle'),
	fontWeight: token('font.weight.regular'),

	marginRight: token('space.100'),
	paddingLeft: token('space.050'),

	'&:hover': {
		textDecoration: 'underline',
		cursor: 'pointer',
		'&::before': {
			/* for IE <= 11 */
			textDecoration: 'none',
		},
	},

	'&:focus': {
		textDecoration: 'underline',
	},
});

const isBylineComponentStyles = css({
	'&::before': {
		content: '"•"',
		display: 'inline-block',
		color: token('color.text.subtle'),
		paddingRight: token('space.100'),
		/* for IE <= 11 */
		textDecoration: 'none',
	},
});

interface AnalyticsBylineWrapperProps extends HTMLProps<HTMLSpanElement> {
	isBylineComponent?: boolean;
	abbreviatePageViews?: boolean;
	useBadgeStyle?: boolean;
}

export const AnalyticsBylineWrapper = (props: AnalyticsBylineWrapperProps) => {
	const { isBylineComponent, abbreviatePageViews, children, useBadgeStyle, ...spanProps } = props;

	return (
		<span
			{...spanProps}
			css={[
				!useBadgeStyle ? analyticsBylineWrapperStyles : null,
				isBylineComponent && !abbreviatePageViews ? isBylineComponentStyles : null,
			]}
		>
			{children}
		</span>
	);
};
