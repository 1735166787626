/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type HTMLProps } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { Flex, Text } from '@atlaskit/primitives';
import { N10, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const Container = (props: HTMLProps<HTMLDivElement>) => {
	return (
		<Flex alignItems="center" direction="column">
			{props.children}
		</Flex>
	);
};

// No lint errors when composing a css style after the object, however we want this order of precedence
// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const valueStyles = css({
	font: token('font.heading.xxlarge'),
	fontWeight: token('font.weight.regular'),
	display: 'flex',
	alignItems: 'center',
	margin: 0,
	height: '40px',
	color: token('color.text', N800),
});

// There is no current Visual Refresh solution for headings with custom css and custom props.
// Tracked here: https://hello.atlassian.net/wiki/spaces/CCECO/pages/4430605549/Visual+Refresh+Icon+Typography+Manual+Fix+List
export const Value = (props: HTMLProps<HTMLLabelElement>) => {
	// eslint-disable-next-line jsx-a11y/label-has-associated-control
	return <label {...props} css={[valueStyles]} />;
};

export const Metric = (props: HTMLProps<HTMLLabelElement>) => {
	return <Text size="small">{props.children}</Text>;
};

interface SkeletonBlockProps extends HTMLProps<HTMLDivElement> {
	height: number;
	marginTop: number;
}
const skeletonBlockStyles = css({
	display: 'flex',
	backgroundColor: token('color.skeleton', N10),
	width: '40px',
	borderRadius: '3px',
	alignItems: 'center',
});

export const SkeletonBlock = (props: SkeletonBlockProps) => {
	const { height, marginTop, ...divProps } = props;
	return (
		<div
			{...divProps}
			css={[skeletonBlockStyles]}
			style={{
				height: `${height}px`,
				marginTop: `${marginTop}px`,
			}}
		/>
	);
};
