import React from 'react';

import { Pressable, xcss } from '@atlaskit/primitives';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ChartTrendIcon from '@atlaskit/icon/core/chart-trend';

const badgeStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: 'space.050',

	color: 'color.text.subtle',
	font: 'font.body.small',
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	paddingTop: 'space.025',
	paddingBottom: 'space.025',
	height: '24px',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

type BadgeStyleAnalyticsBylineProps = {
	message: React.ReactNode | string;
	onClick?: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
	iconLabel: string;
};

export const BadgeStyleAnalyticsByline = ({
	message,
	onClick,
	iconLabel,
}: BadgeStyleAnalyticsBylineProps) => (
	<Pressable onClick={onClick} xcss={badgeStyles} testId="badge-style-analytics-byline">
		<ChartTrendIcon label={iconLabel} />
		{message}
	</Pressable>
);
