import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	width: '400px',
	display: 'flex',
	padding: token('space.250'),
	flexDirection: 'column',
	boxSizing: 'border-box',
	position: 'relative',
	overflowX: 'hidden',
	backgroundColor: token('elevation.surface.overlay'),
	boxShadow: token('elevation.shadow.overlay'),

	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRContainer = styled.div<{ zIndex: number }>({
	position: 'absolute',
	display: 'none',
	marginTop: token('space.075'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: (props) => props.zIndex,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRChartSkeleton = styled.div({
	height: '68px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRLoadingText = styled.span({
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	display: 'flex',
	justifyContent: 'center',
	textAlign: 'center',
	color: token('color.text.subtle'),
	margin: `${token('space.150')} 0px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DialogCenteredRow = styled.div({
	display: 'flex',
	justifyContent: 'center',
});
