import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import ChartTrendIcon from '@atlaskit/icon/core/chart-trend';
import Tooltip from '@atlaskit/tooltip';

import { MigrationChangeboardingBylinePulse } from '../components/common/MigrationChangeboardingAssets';

import { BadgeStyleAnalyticsByline } from './BadgeStyleAnalyticsByline';
import * as S from './AnalyticsByLineStyled';

const i18n = defineMessages({
	viewers: {
		id: 'confluence-analytics.byline.user-views.message',
		defaultMessage:
			'{count, plural, =0 {Analytics} one {1 person viewed} other {{count} people viewed}}',
		description: 'Analytics byline text to represent the count of unique viewers for the page.',
	},
	abbreviatedViewers: {
		id: 'confluence-analytics.byline.abbreviated-user-views.message',
		defaultMessage: '{count}',
		description:
			'Abbreviated analytics byline text to represent the count of unique viewers for the page.',
	},
	pageViewers: {
		id: 'confluence-analytics.byline.page-viewers.message',
		defaultMessage: 'Views',
		description: 'Text to represent the number of page viewers.',
	},
});

export type AnalyticsBylineProps = {
	contentId: string;
	count: number;
	isBylineComponent?: boolean;
	onClick?: (e?: React.MouseEvent<Element>) => void;
	href?: string;
	testId?: string;
	abbreviatePageViews?: boolean;
	useBadgeStyle?: boolean;
};

type TooltipContainerProps = {
	abbreviatePageViews?: boolean;
	children: React.ReactNode;
};

// The tooltip needs to be added for abbreviated page views since the text only shows the number of viewers without describing what the number is
const TooltipContainer = ({ abbreviatePageViews, children }: TooltipContainerProps) => {
	if (abbreviatePageViews) {
		return (
			<Tooltip content={<FormattedMessage {...i18n.pageViewers} />} hideTooltipOnClick>
				{children}
			</Tooltip>
		);
	}
	return <>{children}</>;
};

const handleOnClick =
	(onClick?: (e?: React.MouseEvent<Element>) => void) =>
	(e?: React.MouseEvent<Element>): void => {
		if (onClick) {
			return onClick(e);
		}
		return undefined;
	};

const formatAbbreviatedNumber = (locale: string, count: number): string => {
	try {
		const numberFormatter = Intl.NumberFormat(locale, {
			notation: 'compact',
			compactDisplay: 'short',
		});
		return numberFormatter.format(count).toLocaleLowerCase();
	} catch (e) {
		return String(count);
	}
};

export const AnalyticsByline = ({
	contentId,
	isBylineComponent,
	onClick,
	href,
	count,
	testId,
	abbreviatePageViews,
	useBadgeStyle,
}: AnalyticsBylineProps) => {
	const intl = useIntl();
	const value = formatAbbreviatedNumber(intl.locale, count);
	const analyticsIconLabel = abbreviatePageViews ? intl.formatMessage(i18n.pageViewers) : '';

	const analyticsBylineMessage = (
		<FormattedMessage
			{...(abbreviatePageViews ? i18n.abbreviatedViewers : i18n.viewers)}
			values={{ count: value }}
		/>
	);

	return (
		<TooltipContainer abbreviatePageViews={abbreviatePageViews}>
			<S.AnalyticsBylineWrapper
				isBylineComponent={isBylineComponent}
				abbreviatePageViews={abbreviatePageViews}
				data-testid={testId}
				useBadgeStyle={useBadgeStyle}
			>
				<MigrationChangeboardingBylinePulse contentId={contentId}>
					{!useBadgeStyle ? (
						<Button
							href={href}
							onClick={handleOnClick(onClick)}
							appearance="subtle-link"
							spacing="none"
							// TODO: (from codemod) Buttons with "component", "css" or "style" prop can't be automatically migrated with codemods. Please migrate it manually.
							// leaving as-is for now, as this is a temporary solution -- ENGHEALTH-24908/CCECO-4418
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
							style={{ fontWeight: 'unset' }}
						>
							<S.AnalyticsIconWrapper>
								<ChartTrendIcon label={analyticsIconLabel} color="currentColor" />
							</S.AnalyticsIconWrapper>
							{analyticsBylineMessage}
						</Button>
					) : (
						<BadgeStyleAnalyticsByline
							iconLabel={analyticsIconLabel}
							message={analyticsBylineMessage}
							onClick={handleOnClick(onClick)}
						/>
					)}
				</MigrationChangeboardingBylinePulse>
			</S.AnalyticsBylineWrapper>
		</TooltipContainer>
	);
};
