import React, { type FC, type ReactNode } from 'react';

import { useIntl } from 'react-intl-next';

import * as S from './styled';

export type InfoPanelProps = {
	value: number | string;
	metric: ReactNode;
	isLoading: boolean;
	testId?: string;
};

const skeleton = (
	<S.Container>
		<S.SkeletonBlock height={28} marginTop={4} />
		<S.SkeletonBlock height={14} marginTop={8} />
	</S.Container>
);

const formatAbbreviatedNumber = (locale: string, count: number): string => {
	try {
		const numberFormatter = Intl.NumberFormat(locale, {
			notation: 'compact',
			compactDisplay: 'short',
		});
		return numberFormatter.format(count).toLocaleLowerCase();
	} catch (e) {
		return `${count}`;
	}
};

const InfoPanel: FC<InfoPanelProps> = ({ value, metric, isLoading, testId }: InfoPanelProps) => {
	const intl = useIntl();
	const valueDisplay =
		typeof value === 'number' ? formatAbbreviatedNumber(intl.locale, value) : value;

	const testIdText = testId && isLoading ? `${testId}-skeleton` : testId;
	const display = isLoading ? (
		skeleton
	) : (
		<S.Container>
			<S.Value>{valueDisplay}</S.Value>
			<S.Metric>{metric}</S.Metric>
		</S.Container>
	);

	return <div data-testid={testIdText}>{display}</div>;
};

export default InfoPanel;
