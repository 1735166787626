import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UserListContainer = styled.ul({
	listStyleType: 'none',
	listStylePosition: 'inside',
	display: 'flex',
	flexDirection: 'column',
	margin: '0px',
	padding: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UserListRow = styled.li({
	margin: '0px',
	padding: '0px',
});

// There is default padding of 6px for the AvatarItem
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UserContainer = styled.div({
	marginLeft: token('space.negative.075'),
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	cursor: 'default',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SecondaryText = styled.span({
	font: token('font.body.small'),
	color: token('color.text.subtle'),
	flexShrink: 0,
});
