import React, { type ReactNode, useCallback } from 'react';

import { Inline } from '@atlaskit/primitives';

import * as S from './styled';

const skeletonPillHeight = 28;

export type Pill<TPillName extends string = string> = {
	content: ReactNode;
	name: TPillName;
	skeletonWidth?: number;
};

export type PillSelectorProps<TPillName extends string> = {
	pills: Pill<TPillName>[];
	selectedPill: TPillName;
	onPillSelected: (pillName: TPillName) => void;
	fireAnalyticsEvent?: (pillName: TPillName) => void;
	isLoading: boolean;
	testId?: string;
};

export const PillSelector = <TPillName extends string>({
	selectedPill,
	pills,
	isLoading,
	onPillSelected,
	fireAnalyticsEvent,
	testId,
}: PillSelectorProps<TPillName>) => {
	const onPillSelectedInternal = useCallback(
		(pillName: TPillName) => {
			onPillSelected(pillName);
			fireAnalyticsEvent?.(pillName);
		},
		[onPillSelected, fireAnalyticsEvent],
	);

	if (isLoading) {
		const skeletonPills = pills.map((pill) => (
			<S.SkeletonPill key={pill.name} width={pill.skeletonWidth} height={skeletonPillHeight} />
		));

		return (
			<Inline space="space.100" testId={`${testId}-skeleton`}>
				{skeletonPills}
			</Inline>
		);
	}

	const pillButtons = pills.map((pill) => (
		<S.PillButton
			id={pill.name}
			key={pill.name}
			isSelected={pill.name === selectedPill}
			onClick={() => onPillSelectedInternal(pill.name)}
			role="tab"
			aria-controls={pill.name}
			aria-selected={pill.name === selectedPill}
			testId={pill.name}
		>
			{pill.content}
		</S.PillButton>
	));

	return (
		<Inline space="space.100" role="tablist" testId={testId}>
			{pillButtons}
		</Inline>
	);
};
