import type { FC } from 'react';
import React, { useEffect, useState, useCallback, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { PopupProps } from '@atlaskit/popup';
import { layers } from '@atlaskit/theme/constants';

import { PageSegmentLoadStart, PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { Attribution, ErrorDisplay, withErrorBoundary } from '@confluence/error-boundary';
import { ExperienceStart, ExperienceTrackerContext } from '@confluence/experience-tracker';
import { markErrorAsHandled } from '@confluence/graphql';
import { getMark } from '@confluence/performance';
import { withFlags } from '@confluence/flags';
import type { FlagsStateContainer } from '@confluence/flags';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { SSR_PARTIAL_COMPONENT, useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import { useChoreographerAssets } from '@confluence/choreographer-services';

import { useSSR, SSREvent } from '../../hooks';
import { CONTENT_ANALYTICS_VIEWERS_METRIC } from '../../perf.config';
import { AnalyticsDialogSsr } from '../AnalyticsDialog/AnalyticsDialogSsr';
import { isExpectedError } from '../analyticsUtils';

import { SSR_EVENTS_CAPTURE_KEY } from './constants';
import { useAnalyticsByLineEntryPoint } from './useAnalyticsByLineEntryPoint';

export type AnalyticsByLineProps = {
	contentId: string;
	flags: FlagsStateContainer;
	contentAuthorId?: string;
};

const AnalyticsByLineInternal: FC<AnalyticsByLineProps> = ({ contentId, contentAuthorId }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const renderServerPlaceholder = useRenderServerPlaceholder();
	const { onSSRCapturedEvent, wasComponentSSRed, wasSSREventTriggered } = useSSR({
		ssrEventCaptureKey: SSR_EVENTS_CAPTURE_KEY,
		name: SSR_PARTIAL_COMPONENT.AnalyticsByLine,
	});
	const [isPopupOpen, setPopupOpen] = useState(wasSSREventTriggered(SSREvent.CLICK));

	const {
		error,
		loading,

		popupContent,
		popupContentExperience,
		onPopupContentClose,

		popupTrigger,
		popupTriggerExperience,
		onPopupTriggerSSREvent,
		hideAnalyticsByLine,
	} = useAnalyticsByLineEntryPoint({
		contentId,
		wasComponentSSRed,
		contentAuthorId,
	});

	useEffect(() => {
		if (wasComponentSSRed) {
			// https://hello.atlassian.net/l/cp/942Xd6LH#Instrumenting-your-feature
			CONTENT_ANALYTICS_VIEWERS_METRIC.markFMP(getMark('CFP-63.ssr-ttr'));
		}
	}, [wasComponentSSRed]);

	const experienceTracker = useContext(ExperienceTrackerContext);

	useEffect(() => {
		if (!wasComponentSSRed) {
			setPopupOpen(false);
		}
	}, [contentId, wasComponentSSRed]);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'displayed',
				actionSubject: 'analytics',
				actionSubjectId: contentId,
				objectId: contentId,
				source: 'confluence-frontend',
			},
		}).fire();
	}, [contentId, createAnalyticsEvent]);

	const onPopupClose: PopupProps['onClose'] = (...args) => {
		onPopupContentClose?.(...args);
		setPopupOpen(false);
	};

	const onPopupTriggerClick = useCallback(() => {
		onPopupTriggerSSREvent?.(SSREvent.CLICK);
		setPopupOpen(true);
		experienceTracker.start({ name: popupContentExperience });
	}, [experienceTracker, onPopupTriggerSSREvent, popupContentExperience]);
	useEffect(() => {
		onSSRCapturedEvent(SSREvent.CLICK, onPopupTriggerClick);
	}, [onSSRCapturedEvent, onPopupTriggerClick]);

	const onPopupTriggerFocus = useCallback(() => {
		onPopupTriggerSSREvent?.(SSREvent.FOCUS);
	}, [onPopupTriggerSSREvent]);
	useEffect(() => {
		onSSRCapturedEvent(SSREvent.FOCUS, onPopupTriggerFocus);
	}, [onSSRCapturedEvent, onPopupTriggerFocus]);

	const onPopupTriggerHover = useCallback(() => {
		onPopupTriggerSSREvent?.(SSREvent.HOVER);
	}, [onPopupTriggerSSREvent]);
	useEffect(() => {
		onSSRCapturedEvent(SSREvent.HOVER, onPopupTriggerHover);
	}, [onSSRCapturedEvent, onPopupTriggerHover]);

	useEffect(() => {
		if (error) {
			if (popupTriggerExperience) {
				experienceTracker.abort({
					name: popupTriggerExperience,
					reason: error.name,
				});
			}
			if (isExpectedError(error)) {
				markErrorAsHandled(error);
			}
		}
	}, [error, experienceTracker, popupTriggerExperience]);

	useEffect(() => {
		if (!loading && !error && popupTriggerExperience) {
			experienceTracker.succeed({ name: popupTriggerExperience });
		}
	}, [error, experienceTracker, loading, popupTriggerExperience]);

	const zIndex = layers.dialog();

	const { Popup } = useChoreographerAssets();

	return !hideAnalyticsByLine ? (
		<>
			{popupTriggerExperience ? <ExperienceStart name={popupTriggerExperience} /> : null}
			<Popup
				testId="analytics-by-line-popup"
				isOpen={isPopupOpen}
				onClose={onPopupClose}
				placement="bottom-start"
				trigger={(props) => {
					const { 'aria-expanded': ariaExpanded, ...triggerProps } = props;
					const trigger = popupTrigger({
						...props,
						onClick: onPopupTriggerClick,
					});

					return (
						// eslint-disable-next-line jsx-a11y/no-static-element-interactions
						<span
							{...triggerProps}
							onFocus={onPopupTriggerFocus}
							onMouseOver={onPopupTriggerHover}
							data-vc="analytics-byline-wrapper"
							{...ssrPlaceholderIdProp}
						>
							<PageSegmentLoadStart
								metric={CONTENT_ANALYTICS_VIEWERS_METRIC}
								// If we did SSR we want the start time to be the page start time.
								// If we did not SSR we want the start time to be now.
								isCustomStart={!wasComponentSSRed}
							/>
							{error && <ErrorDisplay error={error} />}
							{trigger && (
								<>
									{trigger}
									<PageSegmentLoadEnd metric={CONTENT_ANALYTICS_VIEWERS_METRIC} />
								</>
							)}
						</span>
					);
				}}
				content={(props) => popupContent({ ...props, zIndex })}
				zIndex={zIndex}
				messageId="confluence-analytics-analytics-by-line-popup"
				messageType="transactional"
			/>
			{renderServerPlaceholder && <AnalyticsDialogSsr zIndex={zIndex} />}
		</>
	) : null;
};

export const AnalyticsByLine = withErrorBoundary({
	attribution: Attribution.CC_ANALYTICS,
})(withFlags(AnalyticsByLineInternal));
